import React, { useState } from 'react';
import Chatbot from 'react-simple-chatbot';
import { Modal } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMinimize, faTimes, faWindowMaximize } from '@fortawesome/free-solid-svg-icons';
import ChatData from './ChatbotSteps'
import ChatbotIcon from './Components/CloudBoxLogo.png';
import './Components/App.css';
import { ThemeProvider } from 'styled-components';
import Avathar from './Components/ChatbotMan.png';
import C99 from './Components/CloudBoxLogoC99.png';
import useravatar1 from './Components/ChatbotAvatar.jpeg'
import chatbotuser from './Components/MicrosoftTeams-image2.png';
import { handleChatEnd } from './ChatbotSteps';

function App() {
  const [showFullChatbot, setShowFullChatbot] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [showOffCanvas, setShowOffCanvas] = useState(false);

  const toggleMinimizeChatbot = () => {
    setShowFullChatbot(!showFullChatbot);
    setMinimized(!minimized);
    setShowOffCanvas(true);
  };

  const closeFullChatbot = () => {
    setShowFullChatbot(false);
    setShowOffCanvas(false);
  };

  const openFullChatbot = () => {
    setShowFullChatbot(true);
    setMinimized(false);
  };

  const maximizeChatbot = () => {
    closeFullChatbot();
    openFullChatbot();
  };

  const customHeader = (
    <div
      style={{
        height: '60px',
        lineHeight: '60px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: '10px',
        position: 'sticky',
        top: '0',
        zIndex: '1002',
        bottom: minimized ? 'auto' : '0',
        backgroundColor: '#6EC1E4'
      }}>
      {showOffCanvas ? null : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}>
          {minimized ? (
            <span
              style={{
                fontWeight: 'bold',
                color: 'white',
                fontSize: '15px'
              }}></span>
          ) : (
            <>
              <img src={ChatbotIcon}
                alt="Chatbot"
                style={{
                  width: '80px',
                  height: '60px',
                  objectFit: 'cover',
                  cursor: 'pointer',
                  paddingLeft: '10px'
                }} />
              <span
                style={{
                  fontWeight: 'bold',
                  marginLeft: '5px',
                  color: '#050a4a',
                  fontSize: '18px',
                  paddingLeft: '10px'
                }}></span>
            </>
          )}
        </div>
      )}
      <div
        style={{
          float: 'right',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center'
        }}>
        <FontAwesomeIcon
          icon={minimized ? faWindowMaximize : faWindowMinimize}
          style={{ marginRight: '18px', color: 'black', fontSize: '15px' }}
          onClick={toggleMinimizeChatbot}
          title={minimized ? 'Maximize' : 'Minimize'}
          data-tip={minimized ? 'Maximize' : 'Minimize'}
        />
        <FontAwesomeIcon
          icon={faTimes}
          style={{ color: 'red', fontSize: '20px', paddingRight: '15px' }}
          onClick={closeFullChatbot}
          title="Close"
          data-tip="Close"
        />
      </div>
    </div>
  );
  const theme = {
    headerBgColor: '#6EC1E4',
    background: '#f8f8f8',
    headerFontColor: '#fff',
    botBubbleColor: '#e0dcdc',
    botFontColor: 'black',
    userBubbleColor: '#f6eded',
    userFontColor: 'black',
  };
  return (
    <div>       
      {!showFullChatbot && !showOffCanvas && (
        <div
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            cursor: 'pointer',
            zIndex: '1000',
            transition: 'right 0.3s'
          }} onClick={openFullChatbot}>
          <img src={Avathar}
            alt="Company Logo"
            style={{
              width: '75px',
              height: '75px',
              objectFit: 'cover',
              borderRadius: '50%'
            }} />
        </div>
      )}
      {showFullChatbot && (
        <Modal
          open={showFullChatbot}
          onClose={closeFullChatbot}
          size="tiny"
          style={{
            position: 'fixed',
            bottom: minimized ? '0' : '10px',
            right: minimized ? '20px' : '28%',
            transform: minimized ? 'translateX(0%)' : 'translateX(90%)',
            width: minimized ? 'auto' : '30%',
            height: minimized ? 'auto' : '60%',
            zIndex: '1001',
            overflow: 'hidden',
            transition: 'right 0.3s, width 0.3s'
          }}>
          <Modal.Content
            style={{
              maxHeight: minimized ? 'none' : '350px'
            }}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}>
              <div
                className='chatbot-wrapper'
                style={{
                  padding: '15px',
                  height: '350px',
                 
                }}>
                <ThemeProvider theme={theme} >
                  <Chatbot
                    steps={ChatData}
                    headerComponent={customHeader}
                    handleEnd={(ChatData) => handleChatEnd(ChatData)}
                    botAvatar={C99}
                    userAvatar={useravatar1}
                    style={{
                      height: "100%",
                      background: `url(${chatbotuser})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundAttachment: 'fixed',
                      backgroundOpacity: '0.5'
                    }}
                    enableSmoothScroll autoScrollDown />
                </ThemeProvider>
              </div>
            </div>
          </Modal.Content>
        </Modal>
      )}
      {showOffCanvas && (
        <div
          style={{
            position: 'fixed',
            bottom: '5px',
            right: minimized ? '0' : '28%',
            width: minimized ? '25%' : '20%',
            background: '#6EC1E4',
            boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.2)',
            zIndex: '1003',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '13px',
            transition: 'right 0.3s'
          }}>
          <img src={ChatbotIcon}
            alt="Chatbot"
            style={{
              width: '60px',
              height: '50px',
              objectFit: 'cover',
              cursor: 'pointer',
              marginLeft: '25px'
            }} />
          <p
            style={{
              margin: '5px',
              color: 'black',
              fontSize: '16px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}></p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '20px'
            }}>
            <FontAwesomeIcon
              icon={faWindowMaximize}
              style={{
                marginRight: '30%',
                color: 'black',
                cursor: 'pointer'
              }}
              onClick={maximizeChatbot}
            />
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                color: 'red',
                marginRight: '20%',
                fontSize: '16px',
                cursor: 'pointer'
              }}
              onClick={closeFullChatbot}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
