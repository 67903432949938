import { Table, Form } from 'react-bootstrap';
import axios from 'axios';
import { useState, useEffect } from 'react';
//import { API_ENDPOINTS } from './Config';

const response =await fetch('/manifest.json');
const manifest = await response.json();

let generatedUserId = null;
const sendUserDataToServer = async () => {
  const name = localStorage.getItem("name") || '';
  const email = localStorage.getItem("email") || '';
  try {
    const userData = {
      Name: name,
      Email: email,
    };
    // const response = await axios.post('https://localhost:7091/api/Users/Users', userData, {
     // const response = await axios.post(API_ENDPOINTS.USERS, userData, {
      const response = await axios.post(manifest.apiEndpoints.USERS, userData,{
     headers: {
        'Content-Type': 'application/json',
      },
    });
   // console.log('User data sent successfully', response.data);
    generatedUserId = response.data.User_Id;
  } catch (error) {
    console.error('Error sending user data:', error);
    console.error('Response data:', error.response.data);
    console.error('Response status:', error.response.status);
    console.error('Response headers:', error.response.headers);
    throw error;
  }
};

const handleConfigurationData = async (configurationData, userClickedYes) => {
  try {
   // const response = await axios.post(API_ENDPOINTS.CONFIGURATIONS, configurationData, {
    const response = await axios.post(manifest.apiEndpoints.CONFIGURATIONS, configurationData,{  
   headers: {
        'Content-Type': 'application/json',
      },
    });
 //   console.log('User configuration data sent successfully', response.data);
    if (userClickedYes) {
    }
  } catch (error) {
    console.error('Error sending configuration data:', error);
    console.error('Response data:', error.response.data);
    console.error('Response status:', error.response.status);
    console.error('Response headers:', error.response.headers);
  }
};
const handleChatEnd = async (ChatData, userClickedYes) => {
  const selectedService = JSON.parse(localStorage.getItem("selectedService")) || {};
  const serviceIdForBackend = Number(selectedService.serviceId) || 0;
  const selectedCore = JSON.parse(localStorage.getItem("selectedCore")) || {};
  const selectedCoreID = Number(selectedCore.value) || 0;
  const selectedRAM = JSON.parse(localStorage.getItem("selectedRAM")) || {};
  const selectedRAMID = Number(selectedRAM.value) || 0;
  const selectedOS = JSON.parse(localStorage.getItem("selectedOS")) || {};
  const selectedOSID = Number(selectedOS.value) || 0;
  const selectedStorage = JSON.parse(localStorage.getItem("selectedStorage")) || {};
  const selectedStorageID = Number(selectedStorage.value) || 0;
  const selectedAddons = JSON.parse(localStorage.getItem("selectedAddons")) || {};
  const selectedAddonsID = Number(selectedAddons.value) || 0;
  const selectedDatabase = JSON.parse(localStorage.getItem("AwsselectedDatabase")) || {};
  const selectedDatabaseID = Number(selectedDatabase.value) || 0;
  const selectedCyberservices = JSON.parse(localStorage.getItem("CyberServices")) || {};
  const selectedCyberServicesOd = Number(selectedCyberservices.value) || 0;
  const selectedmicrosoftbusiness = JSON.parse(localStorage.getItem("MicrosftType1")) || {};
  const selectedMicrosoftbusiness = Number(selectedmicrosoftbusiness.value) || 0;
  const selectedmicrosoftenterprise = JSON.parse(localStorage.getItem("MicrosftType")) || {};
  const selectedemicrosoftenterprise = Number(selectedmicrosoftenterprise.value) || 0;
  const selectedstoragebackup = JSON.parse(localStorage.getItem("StorageService")) || {};
  const selectedstorgaebackupid = Number(selectedstoragebackup.value) || 0;
  const selectedOthers = localStorage.getItem("userTypedResponse") || '';
  const awsselectedCore = JSON.parse(localStorage.getItem("AwsselectedCore")) || {};
  const awsselectedCoreId = Number(awsselectedCore.value) || 0;
  const awsselectedRAM = JSON.parse(localStorage.getItem("AwsselectedRAM")) || {};
  const awsselectedRAMId = Number(awsselectedRAM.value) || 0;
  const awsselectedOS = JSON.parse(localStorage.getItem("AwsselectedOS")) || {};
  const awsselectedOSId = Number(awsselectedOS.value) || 0;
  const awsselectedStorage = JSON.parse(localStorage.getItem("AwsselectedStorage")) || {};
  const awsselectedStorageId = Number(awsselectedStorage.value) || 0;
  const awsselectedAddons = JSON.parse(localStorage.getItem("AwsselectedAddons")) || {};
  const awsselectedAddonsId = Number(awsselectedAddons.value) || 0;
  const userselectyes = localStorage.getItem("Useryes");
  
  const configurationData = {
    User_Id: generatedUserId,
    Service_Id: serviceIdForBackend,
    Core_Id: selectedCoreID,
    RAM_Id: selectedRAMID,
    OS_Id: selectedOSID,
    Storage_Id: selectedStorageID,
    Addons_Id: selectedAddonsID,
    Databases_Id: selectedDatabaseID,
    CyberServices_Id: selectedCyberServicesOd,
    BusinessType_Id: selectedMicrosoftbusiness,
    EnterpriseType_Id: selectedemicrosoftenterprise,
    BackupStroage_Id: selectedstorgaebackupid,
    Others: selectedOthers,
    awsCore: awsselectedCoreId,
    awsRAM: awsselectedRAMId,
    awsStorage: awsselectedOSId,
    awsOS: awsselectedStorageId,
    awsAddons: awsselectedAddonsId,
    Useryes: userselectyes,
  };
  await handleConfigurationData(configurationData, userClickedYes);
};

const getServiceOptions = async () => {
  try {
    // const response = await axios.get('https://localhost:7091/api/Users/Services');
    const response = await axios.get(manifest.apiEndpoints.SERVICES);
    const services = response.data;

    return services.map(service => ({
      value: Number(service.Service_Id),
      label: service.ServiceName,
      // trigger: ({ value }) => {
      //   localStorage.setItem("selectedService", value.toString()); 
      //   return getServiceTrigger(value);
      // }  
      trigger: ({ value }) => {
        const selectedService = { value, serviceId: service.Service_Id };
        localStorage.setItem("selectedService", JSON.stringify(selectedService));
        return getServiceTrigger(value);
      }
    }));
  } catch (error) {
    console.error('Error fetching services:', error);
    return [];
  }
};
const getServiceTrigger = (selectedServiceId) => {
  switch (selectedServiceId) {
    case 1:
      return "VpCloud";
    case 2:
      return "AWScloud";
    case 3:
      return "Cybersecurity";
    case 4:
      return "ITservices";
    case 5:
      return "Storage";
    case 6:
      return "typeresponse";
    default:
      return "MainMenu";
  }
};
const getCyberSecurityOptions = async () => {
  try {
  //  const response = await axios.get('https://localhost:7091/api/Users/CyberSecurity');
  const response = await axios.get(manifest.apiEndpoints.CYBERSECURITY);
    const cyberSecureOptions = response.data;

    return cyberSecureOptions.map(cyberSecurity => ({
      value: cyberSecurity.Cyber_Id,
      label: cyberSecurity.CyberServices,
      // trigger: ({ value }) => {
      //   localStorage.setItem("CyberServices", value);
      //   return getTriggerForCyberSecurity(value);
      // },
      trigger: ({ value }) => {
        const selectedCyberservices = { value }; // Store directly as an object
        localStorage.setItem("CyberServices", JSON.stringify(selectedCyberservices));
        return getTriggerForCyberSecurity(value);
      }
    }));
  } catch (error) {
    console.error('Error fetching Cybersecurity options:', error);
    return [];
  }
};

const getTriggerForCyberSecurity = (CyberServices) => {
  switch (CyberServices) {
    case 1:
      return "Userwaiting11";
    case 2:
      return "Userwaiting11";
    case 3:
      return "Userwaiting11";
    default:
      return "MainMenu";
  }
};
const getmicrosoftbusinessOptions = async () => {
  try {
    // const response = await axios.get('https://localhost:7091/api/Users/MicrosoftBusiness');
    const response = await axios.get(manifest.apiEndpoints.MICROSOFTBUSINESS);
    const microsoftbusinessOptions = response.data;

    return microsoftbusinessOptions.map(business => ({
      value: business.Business_Id,
      label: business.MicrosftType,
      // trigger: ({ value }) => {
      //   localStorage.setItem("MicrosftType1", value);
      //   return getTriggerForMicrosoftbusiness(value);
      // },
      trigger: ({ value }) => {
        const selectedmicrosoftbusiness = { value }; // Store directly as an object
        localStorage.setItem("MicrosftType1", JSON.stringify(selectedmicrosoftbusiness));
        return getTriggerForMicrosoftbusiness(value);
      }
    }));
  } catch (error) {
    console.error('Error fetching Cybersecurity options:', error);
    return [];
  }
};

const getTriggerForMicrosoftbusiness = (MicrosftType1) => {
  switch (MicrosftType1) {
    case 1:
      return "Userwaiting11";
    case 2:
      return "Userwaiting11";
    case 3:
      return "Userwaiting11";
    default:
      return "MainMenu";
  }
};
const getmicrosoftenterpriseOptions = async () => {
  try {
    // const response = await axios.get('https://localhost:7091/api/Users/MicrosoftEnterprise');
    const response = await axios.get(manifest.apiEndpoints.MICROSOFTENTERPRISE);
    const microsoftenterpriseOptions = response.data;
    return microsoftenterpriseOptions.map(enterprise => ({
      value: enterprise.Enterprise_Id,
      label: enterprise.MicrosftType,
      // trigger: ({ value }) => {
      //   localStorage.setItem("MicrosftType", value);
      //   return getTriggerForMicrosoftenterprise(value);
      // },
      trigger: ({ value }) => {
        const selectedmicrosoftenterprise = { value }; // Store directly as an object
        localStorage.setItem("MicrosftType", JSON.stringify(selectedmicrosoftenterprise));
        return getTriggerForMicrosoftenterprise(value);
      }
    }));
  } catch (error) {
    console.error('Error fetching Cybersecurity options:', error);
    return [];
  }
};

const getTriggerForMicrosoftenterprise = (MicrosftType) => {
  switch (MicrosftType) {
    case 1:
      return "Userwaiting11";
    case 2:
      return "Userwaiting11";
    case 3:
      return "Userwaiting11";
    default:
      return "MainMenu";
  }
};
const getbackupstorageOptions = async () => {
  try {
    // const response = await axios.get('https://localhost:7091/api/Users/BackupStorage');
    const response = await axios.get(manifest.apiEndpoints.STORAGEBACKUP);
    const storagebackupOptions = response.data;

    return storagebackupOptions.map(storagebackup => ({
      value: storagebackup.Backup_Id,
      label: storagebackup.StorageService,
      // trigger: ({ value }) => {
      //   localStorage.setItem("StorageService", value);
      //   return getTriggerForstoragebackup(value);
      // },
      trigger: ({ value }) => {
        const selectedstoragebackup = { value }; // Store directly as an object
        localStorage.setItem("StorageService", JSON.stringify(selectedstoragebackup));
        return getTriggerForstoragebackup(value);
      }
    }));
  } catch (error) {
    console.error('Error fetching Cybersecurity options:', error);
    return [];
  }
};

const getTriggerForstoragebackup = (StorageService) => {
  switch (StorageService) {
    case 1:
      return "Userwaiting11";
    case 2:
      return "Userwaiting11";
    case 3:
      return "Userwaiting11";
    default:
      return "MainMenu";
  }
};
const getmicrosoftOptions = async () => {
  try {
   // const response = await axios.get('https://localhost:7091/api/Users/Microsoft');
   const response = await axios.get(manifest.apiEndpoints.MICROSOFT);   
   const storagebackupOptions = response.data;

    return storagebackupOptions.map(storagebackup => ({
      value: storagebackup.MicrosoftType,
      label: storagebackup.MicrosoftType,
      trigger: ({ value }) => {       
        return getTriggerFormicrosoft(value);
      },
    }));
  } catch (error) {
    console.error('Error fetching microsoft options:', error);
    return [];
  }
};

const getTriggerFormicrosoft = (MicrosoftType) => {
  const lowerCaseServiceName = MicrosoftType.toLowerCase();
  switch (lowerCaseServiceName) {
    case 'business':
      return "businessmicrosoft";
    case 'enterprise':
      return "enterprisemicrosoft";
    default:
      return "MainMenu";
  }
};

const Vpnconfig1 = () => {
  const [coreOptions, setCoreOptions] = useState([]);
  const [ramOptions, setRamOptions] = useState([]);
  const [osOptions, setOsOptions] = useState([]);
  const [storageOptions, setStorageOptions] = useState([]);
  const [addonsOptions, setAddonsOptions] = useState([]);

  const [selectedValues, setSelectedValues] = useState({
    core: '',
    ram: '',
    os: '',
    storage: '',
    addons: '',
  });

  const handleDropdownChange = (e, dropdownType) => {
    const selectedValue = e.target.value.trim();
    if (selectedValue !== 0) {
      const idAsNumber = Number(selectedValue);
      const selectedObject = { value: idAsNumber };
      switch (dropdownType) {
        case 'core':
          localStorage.setItem("selectedCore", JSON.stringify(selectedObject));
          break;
        case 'ram':
          localStorage.setItem("selectedRAM", JSON.stringify(selectedObject));
          break;
        case 'os':
          localStorage.setItem("selectedOS", JSON.stringify(selectedObject));
          break;
        case 'storage':
          localStorage.setItem("selectedStorage", JSON.stringify(selectedObject));
          break;
        case 'addons':
          localStorage.setItem("selectedAddons", JSON.stringify(selectedObject));
          break;
        default:
          break;
      }
    }
    else {
      switch (dropdownType) {
        case 'core':
          localStorage.removeItem("selectedCore");
          break;
        case 'ram':
          localStorage.removeItem("selectedRAM");
          break;
        case 'os':
          localStorage.removeItem("selectedOS");
          break;
        case 'storage':
          localStorage.removeItem("selectedStorage");
          break;
        case 'addons':
          localStorage.removeItem("selectedAddons");
          break;
        case 'database':
          localStorage.removeItem("selectedDatabase");
          break;
        default:
          break;
      }
    }
    setSelectedValues(prevValues => ({
      ...prevValues,
      [dropdownType]: selectedValue,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
      //  const coreResponse = await axios.get('https://localhost:7091/api/Users/Cores');
      const coreResponse = await axios.get(manifest.apiEndpoints.CORES);
        setCoreOptions(coreResponse.data.map(core => core.Core));

       // const ramResponse = await axios.get('https://localhost:7091/api/Users/RAM');
       const ramResponse = await axios.get(manifest.apiEndpoints.RAM);
        setRamOptions(ramResponse.data.map(ram => ram.RAM));

        //const osResponse = await axios.get('https://localhost:7091/api/Users/OS');
        const osResponse = await axios.get(manifest.apiEndpoints.OS);
        setOsOptions(osResponse.data.map(os => os.OS));

       // const storageResponse = await axios.get('https://localhost:7091/api/Users/Storage');
       const storageResponse = await axios.get(manifest.apiEndpoints.STORAGE);       
       setStorageOptions(storageResponse.data.map(storage => storage.Storage));

        // const addonsResponse = await axios.get('https://localhost:7091/api/Users/Addons');
        const addonsResponse = await axios.get(manifest.apiEndpoints.ADDONS);
        setAddonsOptions(addonsResponse.data.map(addon => addon.Addons));
      } catch (error) {
        console.error('Error fetching core data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ fontSize: '12px' }}>
      <Table>
        <tbody>
          <tr>
            <td>Core</td>
            <td>
              <Form.Select
                id="coreInput"
                onChange={(e) => handleDropdownChange(e, 'core')}
                style={{ fontSize: '12px',cursor: 'pointer'}}
                value={selectedValues.core}
              >
                <option value="" disabled={!selectedValues.core}>--Select Core--</option>
                {coreOptions.map((Core, Core_Id)  => (
                  <option key={Core_Id} value={Core_Id}>
                    {Core}
                  </option>
                ))}
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>RAM</td>
            <td>
              <Form.Select
                id="ramInput"
                onChange={(e) => handleDropdownChange(e, 'ram')}
                style={{ fontSize: '12px',cursor: 'pointer'}}
                value={selectedValues.ram}
              >
                <option value="" disabled={!selectedValues.ram}>--Select RAM--</option>
                {ramOptions.map((RAM, RAM_Id) => (
                  <option key={RAM_Id} value={RAM_Id}>
                    {RAM}
                  </option>
                ))}
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>Operating System</td>
            <td>
              <Form.Select
                id="osInput"
                onChange={(e) => handleDropdownChange(e, 'os')}
                style={{ fontSize: '12px',cursor: 'pointer'}}
                value={selectedValues.os}
              >
                <option value="" disabled={!selectedValues.os}>--Select OS--</option>
                {osOptions.map((OS, OS_Id) => (
                  <option key={OS_Id} value={OS_Id}>
                    {OS}
                  </option>
                ))}
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>Storage</td>
            <td>
              <Form.Select
                id="storageInput"
                onChange={(e) => handleDropdownChange(e, 'storage')}
                style={{ fontSize: '12px',cursor: 'pointer' }}
                value={selectedValues.storage}
              >
                <option value="" disabled={!selectedValues.storage}>--Select Storage--</option>
                {storageOptions.map((Storage, Storage_Id) => (
                  <option key={Storage_Id} value={Storage_Id}>
                    {Storage}
                  </option>
                ))}
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>Addons</td>
            <td>
              <Form.Select
                id="addonsInput"
                onChange={(e) => handleDropdownChange(e, 'addons')}
                style={{ fontSize: '12px',cursor: 'pointer' }}
                value={selectedValues.addons}
              >
                <option value="" disabled={!selectedValues.addons}>--Select Addons--</option>
                {addonsOptions.map((Addons, Addons_Id) => (
                  <option key={Addons_Id} value={Addons_Id}>
                    {Addons}
                  </option>
                ))}
              </Form.Select>
            </td>
          </tr>
        </tbody>
      </Table>
      <p style={{ fontSize: '11px', textAlign: 'center', color: 'gray' }}>Select the options and click the arrow button below to proceed</p>
    </div>
  );
};
const AwsCongif123 = () => {
  const [coreOptions, setCoreOptions] = useState([]);
  const [ramOptions, setRamOptions] = useState([]);
  const [osOptions, setOsOptions] = useState([]);
  const [storageOptions, setStorageOptions] = useState([]);
  const [addonsOptions, setAddonsOptions] = useState([]);
  const [dbOptions, setDbOptions] = useState([]);

  const [selectedValues, setSelectedValues] = useState({
    core: '',
    ram: '',
    os: '',
    storage: '',
    addons: '',
    db: '',
  });

  const handleDropdownChange = (e, dropdownType) => {
    const selectedValue = e.target.value.trim();
    if (selectedValue !== "") {
      const idAsNumber = Number(selectedValue);
      const selectedObject = { value: idAsNumber };
      switch (dropdownType) {
        case 'core':
          localStorage.setItem("AwsselectedCore", JSON.stringify(selectedObject));
          break;
        case 'ram':
          localStorage.setItem("AwsselectedRAM", JSON.stringify(selectedObject));
          break;
        case 'os':
          localStorage.setItem("AwsselectedOS", JSON.stringify(selectedObject));
          break;
        case 'storage':
          localStorage.setItem("AwsselectedStorage", JSON.stringify(selectedObject));
          break;
        case 'awsaddons':
          localStorage.setItem("AwsselectedAddons", JSON.stringify(selectedObject));
          break;
        case 'db':
          localStorage.setItem("AwsselectedDatabase", JSON.stringify(selectedObject));
          break;
        default:
          break;
      }
    }
    setSelectedValues(prevValues => ({
      ...prevValues,
      [dropdownType]: selectedValue,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const coreResponse = await axios.get(manifest.apiEndpoints.CORES);
        setCoreOptions(coreResponse.data.map(core => core.Core));

        const ramResponse = await axios.get(manifest.apiEndpoints.RAM);
        setRamOptions(ramResponse.data.map(ram => ram.RAM));

        const osResponse = await axios.get(manifest.apiEndpoints.OS);
        setOsOptions(osResponse.data.map(os => os.OS));

        const storageResponse = await axios.get(manifest.apiEndpoints.STORAGE);
        setStorageOptions(storageResponse.data.map(storage => storage.Storage));

        const addonsResponse = await axios.get(manifest.apiEndpoints.ADDONS);
        setAddonsOptions(addonsResponse.data.map(addon => addon.Addons));

        const dbResponse = await axios.get(manifest.apiEndpoints.DATABASE);
        setDbOptions(dbResponse.data.map(db => db.Database_Type));

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ fontSize: '12px' }}>
      <Table>
        <tbody>
          <tr>
            <td>vCPUs</td>
            <td>
              <Form.Select
                id="coreInput"
                onChange={(e) => handleDropdownChange(e, 'core')}
                style={{ fontSize: '12px',cursor: 'pointer' }}
                value={selectedValues.core}
              >
                <option value="" disabled={!selectedValues.core}>--Select vCPUS--</option>
                {coreOptions.map((Core, Core_Id) => (
                  <option key={Core_Id} value={Core_Id}>
                    {Core}
                  </option>
                ))}
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>Memory</td>
            <td>
              <Form.Select
                id="ramInput"
                onChange={(e) => handleDropdownChange(e, 'ram')}
                style={{ fontSize: '12px',cursor: 'pointer' }}
                value={selectedValues.ram}
              >
                <option value="" disabled={!selectedValues.ram}>--Select Memory--</option>
                {ramOptions.map((RAM, RAM_Id) => (
                  <option key={RAM_Id} value={RAM_Id}>
                    {RAM}
                  </option>
                ))}
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>Operating System</td>
            <td>
              <Form.Select
                id="osInput"
                onChange={(e) => handleDropdownChange(e, 'os')}
                style={{ fontSize: '12px',cursor: 'pointer' }}
                value={selectedValues.os}
              >
                <option value="" disabled={!selectedValues.os}>--Select OS--</option>
                {osOptions.map((OS, OS_Id) => (
                  <option key={OS_Id} value={OS_Id}>
                    {OS}
                  </option>
                ))}
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>Storage</td>
            <td>
              <Form.Select
                id="storageInput"
                onChange={(e) => handleDropdownChange(e, 'storage')}
                style={{ fontSize: '12px',cursor: 'pointer' }}
                value={selectedValues.storage}
              >
                <option value="" disabled={!selectedValues.storage}>--Select Storage--</option>
                {storageOptions.map((Storage, Storage_Id) => (
                  <option key={Storage_Id} value={Storage_Id}>
                    {Storage}
                  </option>
                ))}
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>Addons</td>
            <td>
              <Form.Select
                id="addonsInput"
                onChange={(e) => handleDropdownChange(e, 'awsaddons')}
                style={{ fontSize: '12px',cursor: 'pointer' }}
                value={selectedValues.awsaddons}
              >
                <option value="">--Select Addons--</option>
                {addonsOptions.map((Addons, Addons_Id) => (
                  <option key={Addons_Id} value={Addons_Id}>
                    {Addons}
                  </option>
                ))}
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>Database</td>
            <td>
              <Form.Select
                id="dbsInput"
                onChange={(e) => handleDropdownChange(e, 'db')}
                style={{ fontSize: '12px',cursor: 'pointer'}}
                value={selectedValues.db}
              >
                <option value="">--Select Database--</option>
                {dbOptions.map((Database_Type, Database_Id) => (
                  <option key={Database_Id} value={Database_Id} style={{cursor: 'pointer'}}>
                    {Database_Type}
                  </option>
                ))}
              </Form.Select>
            </td>
          </tr>
        </tbody>
      </Table>
      <p style={{ fontSize: '11px', textAlign: 'center', color: 'gray' }}>
        Select the options and click the arrow button below to proceed
      </p>
    </div>
  );
};
const CustomLink = (props) => {
  const handleClick = () => {
    props.triggerNextStep({ trigger: props.trigger });
  };
  return (
    <div>
      <button onClick={handleClick} style={{ cursor: 'pointer', color: '#1a0dab', textDecoration: 'underline', border: 'none', background: 'none',fontSize: '13px'  }}>
    {props.message}
    </button>    
      {props.label}   
  </div>    
  );
};
const AWSCustomLink = (props) => {
  const handleClick = () => {
    props.triggerNextStep({ trigger: props.trigger });
  };
  return (
    <div>       
       <button onClick={handleClick} style={{ cursor: 'pointer', color: '#1a0dab', textDecoration: 'underline', border: 'none', background: 'none',fontSize: '13px'  }}>
    {props.message}
    </button>    
      {props.label}
    </div>
  );
};

let ChatData;
const steps = [
  {
    id: "Greet",
    message: "Hey there! How can i assist you today...?",
    trigger: "Done",
  },
  {
    id: "Done",
    message: "May I know your name...?",
    trigger: "waiting1",
  },
  {
    id: "waiting1",
    user: true,
    validator: (value) => {
      if (/^[A-Za-z ]+$/.test(value)) {
        localStorage.setItem("name", value);
        return true;
      }
      return "Enter only alphabetic characters.";
    },
    trigger: "ValidateEmailAndMobile",
  },
  {
    id: "ValidateEmailAndMobile",
    message: "Hello, {previousValue}! Can you give your work email...?",
    trigger: "waitingForEmail"
  },
  {
    id: "waitingForEmail",
    user: true,
    validator: (value) => {
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        localStorage.setItem("email", value);
        sendUserDataToServer();
        return true;
      }
      return "Please enter a valid email.";
    },
    trigger: "Name"
  },
  {
    id: "Name",
    message: "Thank you for providing your details. What specific services are you interested in?",
    trigger: "issues",
  },
  {
    id: "issues",
    options: await getServiceOptions(),
  },
  {
    id: "VpCloud",
    component: (
      <CustomLink
       message="Click Here"
        label="to Provide details about Virtual Machines configuration you are looking for"
        trigger="Vpnconfig1"
      />
    ),
    asMessage: true,
    // options: [
    //   {
    //     value: "Click Here! to Provide details about Virtual Machine configuration you are looking for",
    //     label: "Click Here! to Provide details about Virtual Machines configuration you are looking for",
    //     trigger: "Vpnconfig1",    
    //   },
    // ]   
  },
  {
    id: "typeresponse",
    message: "Enter your detailed requirements or questions in the provided space.",
    trigger: "typeresponse123",
  },
  {
    id: "typeresponse123",
    user: true,
    validator: (value) => {
      localStorage.setItem("userTypedResponse", value);
      return true;
    },
    trigger: "capturerequest",
  },
  {
    id: "capturerequest",
    message: "We are capture your requirement.Our Account Manager will reach out you soon",
    trigger: "MainMenu",
  },
  {
    id: "Vpnconfig1",
    component: <Vpnconfig1 />,
    trigger: "UserResponse1",
  },
  {
    id: "UserResponse1",
    user: true,
    trigger: "UserResponse"
  },
  {
    id: "UserResponse",
    message: "We are processing your request.Our Account Manager will reach out you soon",
    trigger: "MainMenu"
  },
  {
    id: 'Userwaiting1',
    user: true,
    trigger: "Userwaiting11",
  },
  {
    id: 'Userwaiting11',
    message: "Thank you for confirming.Our Account Manager will reach out you soon",
    trigger: "MainMenu"
  },
  {
    id: "MainMenu",
    message: " Is there anything else you would like to inquire about our services?",
    trigger: "enquiryaboutservices"
  },
  {
    id: "enquiryaboutservices",
    options: [
      {
        value: "Yes",
        label: "Yes",
        // trigger: "issues",      
        trigger: () => {
          localStorage.setItem('Useryes', 'Yes');
          handleChatEnd(ChatData, true);
          return "issues";
        },
      },
      {
        value: "No",
        label: "No",
        trigger: () => {
          localStorage.setItem('Useryes', 'No');                   
          return "niceDay";                 
        },
      }
    ]
  },
  {
    id: "AWScloud",
    component: (
      <AWSCustomLink
      message= "Click Here"
        label="to specify the configuration details for your AWS instance"
        trigger="awsconfig"
      />
    ),
    asMessage: true,
    // options: [
    //   {
    //     value: "Click here! to specify the configuration details for your AWS instance",
    //     label: "Click here! to specify the configuration details for your AWS instance",
    //     trigger: "awsconfig",
    //   },
    // ]
  },
  {
    id: "awsconfig",
    component: <AwsCongif123 />,
    trigger: "UserResponse1",
  },
  {
    id: "Cybersecurity",
    options: await getCyberSecurityOptions(),
  },
  {
    id: "ITservices",
    options: await getmicrosoftOptions(),
  },
  {
    id: "businessmicrosoft",
    options: await getmicrosoftbusinessOptions(),
  },
  {
    id: "enterprisemicrosoft",
    options: await getmicrosoftenterpriseOptions(),
  },
  {
    id: "Storage",
    message:
      "Please specify which type of Storage you want..?",
    trigger: "storagecore",
  },
  {
    id: "storagecore",
    options: await getbackupstorageOptions(),
  },
  {
    id: "niceDay",
    message: "Thank you for considering our services. Have a great day!",       
    end: true, 
  }, 
];
export default steps;
export { handleChatEnd, handleConfigurationData };
